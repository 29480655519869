body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

.ant-divider-horizontal {
  margin: 0;
}

.ant-layout-content {
  width: 100%;
}

.main-content {
  max-width: 1264px;
  margin: 50px auto;
  background-color: white;

  padding: 2rem 3rem;
}

.ant-layout-sider {
  background: none;
}

.loan-variant-column {
  display: flex;
  align-items: center;
  justify-content: end;
}

.plan-debit-remove {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 576px) {
  .main-content {
    margin: 1rem auto;
    padding: 1rem;
  }

  .ant-layout-content {
    padding: 2rem 1rem;
  }

  .loan-variant-column {
    display: 'block';
    padding-right: 0;
  }

  .plan-debit-remove {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.form-card.view-mode .ant-select-selection-search {
  left: 0;
}

.form-card.view-mode .ant-select-selection-search-input,
.form-card.view-mode .ant-input[disabled],
.form-card.view-mode
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.form-card.view-mode .ant-input-number-disabled,
.form-card.view-mode .ant-input-number-disabled .ant-input-number-input,
.form-card.view-mode .ant-picker.ant-picker-disabled,
.form-card.view-mode .ant-picker-input > input,
.form-card.view-mode .ant-input-affix-wrapper-disabled,
.form-card.view-mode .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #000;
  background-color: #fff;
  cursor: auto;
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
  box-shadow: none;
}

.form-card.view-mode .ant-input-suffix {
  display: none;
}

.form-card .ant-input-number-input,
.form-card .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.form-card .ant-input {
  transition: border 0.3s;
}

.form-card.view-mode .ant-select-disabled .ant-select-arrow,
.form-card.view-mode .ant-picker.ant-picker-disabled .ant-picker-suffix {
  display: none;
}

.form-card .ant-card-head {
  padding: 0;
}

.form-card .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}

.ant-layout {
  min-height: 100vh;
}

.body-no-padding > .ant-card-body {
  padding-top: 1px;
  padding-bottom: 0;
}

.loan-form-card .ant-card-head-title {
  padding-top: 0;
}

.loan-form-card .ant-card-extra {
  padding-top: 0;
}

.firebaseui-input,
.firebaseui-input-invalid {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  padding: 6.5px 11px;
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  touch-action: manipulation;
}

.firebaseui-input:hover,
.firebaseui-input-invalid:hover {
  border-color: #b894ff;
  border-right-width: 1px !important;
}

.firebaseui-input:focus,
.firebaseui-input-invalid:focus {
  border-color: #b894ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(153 108 255 / 20%);
}

.firebaseui-button {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
  color: #fff;
  background: #996cff;
  border-color: #996cff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.firebaseui-button:hover {
  color: #fff;
  background: #b894ff;
  border-color: #b894ff;
  text-decoration: none;
}

.firebaseui-button:focus {
  color: #fff;
  background: #b894ff;
  border-color: #b894ff;
  text-decoration: none;
  outline: 0;
}

.firebaseui-id-secondary-link {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  text-shadow: none;
  margin-right: 1rem;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.firebaseui-id-secondary-link:hover {
  color: #b894ff;
  background: #fff;
  border-color: #b894ff;
}

.firebaseui-id-secondary-link:focus {
  color: #b894ff;
  background: #fff;
  border-color: #b894ff;
}

.profile-progress .ant-card-body {
  display: flex;
}

.profile-progress .ant-card-body .progress {
  margin-left: auto;
  padding-left: 2rem;
}

@media only screen and (max-width: 576px) {
  .profile-progress .ant-card-body {
    display: block;
  }

  .profile-progress .ant-card-body .progress {
    margin-left: 0;
    padding-left: 0;
  }
}

.graph-tooltip-row {
  display: flex;
  margin-top: 1rem;
}

.graph-tooltip-row:last-of-type {
  margin-bottom: 1rem;
}

.graph-tooltip-row-label + div {
  margin-left: auto;
  padding-left: 1rem;
}
